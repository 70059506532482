<template>
  <div class="view-contanier" :class="[showFeeTabel ? '' : 'pad']">
    <div v-if="!showFeeTabel" v-loading="loading">
      <div class="opt">
        <div class="opt-title">
          <img src="@/assets/images/order/feeTrial-img.png" alt="" /> 费用试算
        </div>
        <div class="typetab">
          <div
            v-permissson="'PS'"
            :class="['typetab-item', showTypeTab ? '' : 'active']"
            @click="tabChange(0)"
          >
            派送
          </div>
          <div
            v-permissson="'GS'"
            :class="['typetab-item', showTypeTab ? 'active' : '']"
            @click="tabChange(1)"
          >
            关税
          </div>
        </div>
      </div>
      <div class="data-contanier">
        <div v-show="showTypeTab === 0" class="type">
          <TypeTab
            :typeItems="typeItems"
            @handleClick="handleClickTap"
          ></TypeTab>
        </div>
        <div
          class="main"
          :style="{
            marginLeft: showTypeTab === 0 ? '35px' : '0',
            marginTop: showTypeTab === 1 ? '60px' : '30px',
            width: showTypeTab ? '100%' : 'calc(100% - 170px)'
          }"
        >
          <template v-if="showTypeTab === 0">
            <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1">
              <div class="row">
                <el-form-item
                  label="始发地"
                  prop="initial"
                  style="margin-right: 10px"
                >
                  <div class="field">
                    <div class="input">
                      <el-cascader
                        v-model="ruleForm1.initial"
                        :options="initialData"
                        style="width: 100%"
                        placeholder="请选择始发地"
                        @change="handleChangeInit"
                      >
                      </el-cascader>
                    </div>
                  </div>
                </el-form-item>
                <template v-if="showAddRess === 1">
                  <el-form-item
                    label="目的地"
                    prop="purpose"
                    style="margin-right: 10px"
                  >
                    <div class="field">
                      <div class="input">
                        <el-select
                          v-model="ruleForm1.purpose"
                          placeholder="请选择目的地"
                          style="width: 100%"
                        >
                          <el-option
                            v-for="item in purposeData"
                            :key="item.value"
                            :label="item.code + '-' + item.label"
                            :value="item.code"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <el-form-item
                  label="收件邮编"
                  prop="postalCode"
                  style="margin-right: 20px"
                >
                  <div class="field">
                    <div class="input">
                      <el-autocomplete
                        v-model="ruleForm1.postalCode"
                        style="width: 100%"
                        :fetch-suggestions="querySearchAsync"
                        placeholder="请输入收件邮编"
                        @select="handleSelectPostCode"
                      >
                        <template slot-scope="{ item }">
                          <div class="name">{{ item.postalCode }}</div>
                          <span class="addr">{{
                            item.cityName +
                              "," +
                              item.provinceCode +
                              "," +
                              item.postalCode
                          }}</span>
                        </template>
                      </el-autocomplete>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="私人地址" prop="residential">
                  <div class="field">
                    <div class="switch">
                      <el-switch
                        v-model="ruleForm1.residential"
                        :active-text="ruleForm1.residential ? '开启' : '关闭'"
                        inactive-color="#dcdfe6"
                      >
                      </el-switch>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="field">
                    <div
                      v-if="accountsData.quotesType === 2"
                      class="btn"
                      @click="drawer = true"
                    >
                      <img src="@/assets/images/order/rmb.png" />
                      卡派询价
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div class="title"><span>*</span> 选择账号</div>
              <div class="row" style="margin-top: 5px">
                <div
                  v-for="(item, index) in accountsList"
                  :key="item.expressAccountId"
                  class="account"
                >
                  <div class="account-img">
                    <img :src="require(`@/assets/images/order/${item.expressSupplier.toLowerCase()}.png`)">
                  </div>
                  <div class="account-info1">
                    账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 号：<span
                      class="info"
                    >{{ item.accountNumber }}</span
                    >
                  </div>
                  <div class="account-info2">
                    服务类型：<span class="info">{{
                      item.expressService
                    }}</span>
                  </div>
                  <i
                    class="el-icon-remove-outline"
                    @click="handleDeleteAcc(index)"
                  ></i>
                </div>
                <div class="addaccount" @click="handleAddAccount">
                  <div class="addtext">
                    <img src="@/assets/images/order/add.png" alt="" /> 选择账号
                  </div>
                </div>
              </div>
              <template v-if="showAddRess === 0 && accountsList.length > 0">
                <div class="title">地址</div>
                <div class="row">
                  <div class="address">
                    <el-input
                      v-model="addressLine"
                      placeholder="请输入地址"
                      style="width: 650px;"
                    ></el-input>
                  </div>
                </div>
              </template>
              <div class="title field">
                <span class="require">* </span>包裹信息
              </div>
              <div class="package">
                <el-form
                  ref="ruleForm2"
                  class="row"
                  :model="ruleForm2"
                  :rules="rules2"
                >
                  <el-form-item label="件数" prop="packageNumber">
                    <div class="field" style="margin-right: 10px">
                      <div class="input2">
                        <el-input
                          v-model="ruleForm2.packageNumber"
                          placeholder="请输入件数"
                          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        ></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="单件实重" prop="weight">
                    <div class="field" style="margin-right: 10px">
                      <div class="input3">
                        <el-input
                          v-model="ruleForm2.weight"
                          placeholder="请输入单件实重"
                          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        ></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="长" prop="length">
                    <div class="field" style="margin-right: 10px">
                      <div class="input2">
                        <el-input
                          v-model="ruleForm2.length"
                          placeholder="请输入长度"
                          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        ></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="宽" prop="width">
                    <div class="field" style="margin-right: 10px">
                      <div class="input2">
                        <el-input
                          v-model="ruleForm2.width"
                          placeholder="请输入宽度"
                          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        ></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="高" prop="height">
                    <div class="field" style="margin-right: 10px">
                      <div class="input2">
                        <el-input
                          v-model="ruleForm2.height"
                          placeholder="请输入高度"
                          oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        ></el-input>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="单位" prop="unitType">
                    <div class="field" style="margin-right: 10px">
                      <div class="input2">
                        <el-select
                          v-model="ruleForm2.unitType"
                          placeholder="请选择单位"
                          style="width: 100%"
                        >
                          <el-option label="LB/IN" value="0"> </el-option>
                          <el-option label="KG/CM" value="1"> </el-option>
                        </el-select>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="field" style="margin-right: 10px">
                      <el-button
                        type="primary"
                        @click="handleAddPack('ruleForm2')"
                      >添加</el-button
                      >
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="field">
                      <el-button
                        type="primary"
                        @click="resetTabel"
                      >重置</el-button
                      >
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <div class="packageList">
                <ul>
                  <li class="header">
                    <div class="data">
                      <div>包裹号</div>
                      <div>实重</div>
                      <div>长</div>
                      <div>宽</div>
                      <div>高</div>
                      <div>单位</div>
                      <div>操作</div>
                    </div>
                  </li>
                  <div class="packageList-content">
                    <li
                      v-for="(item, index) in packageList"
                      :key="item.packageNumber"
                    >
                      <div class="data">
                        <div>{{ item.packageNumber }}</div>
                        <div>{{ item.realWeight.weight }}</div>
                        <div>{{ item.dimension.length }}</div>
                        <div>{{ item.dimension.width }}</div>
                        <div>{{ item.dimension.height }}</div>
                        <div>
                          {{
                            item.dimension.unitType === 0 ? "LB/IN" : "KG/CM"
                          }}
                        </div>
                        <div :style="{ width: packageList.length > 3 ? 'calc(16.66% - 12px)' : '16.66%' }" class="delete">
                          <i
                            class="el-icon-delete"
                            title="删除"
                            @click="handleDeleteBack(index)"
                          />
                        </div>
                      </div>
                    </li>
                  </div>
                </ul>
                <div class="sum">
                  <span
                  >总实重：<span class="sum-no">{{ total }}KG</span></span
                  >
                  <span
                    style="margin-left: 40px"
                  >总件数：<span
                    class="sum-no"
                  >{{ packageList.length }}件</span
                  ></span
                  >
                </div>
              </div>
              <div class="fee">
                <el-button
                  class="feeBtn"
                  @click="handleFeeCompute('ruleForm1')"
                >费用估算</el-button
                >
              </div>
            </el-form>
          </template>
          <template v-if="showTypeTab === 1">
            <tariff-fee></tariff-fee>
          </template>
        </div>
      </div>
      <select-account-dialog
        :accountsListA="accountsList"
        :accountsData="accountsData"
        :dialogFormVisible="dialogFormVisible"
        @ok="ok"
        @cancel="cancel"
      ></select-account-dialog>
    </div>
    <template v-else>
      <div class="tabel-data">
        <div class="back-fee">
          <div class="back" @click="handleBackFee">
            <img src="@/assets/images/order/back.png" alt="" />返回估算
          </div>
          <div class="current"><i class="el-icon-arrow-right"></i>估算结果</div>
        </div>
        <BaseTable
          v-if="height !== 0"
          :height="height"
          :isPager="false"
          :tableData="tableData"
          :loading="loading"
        >
          <template slot="errorMessage" slot-scope="row">
            <div
              v-if="row.tIndex === 0 && !row.scope.errorMessage"
              class="optimal flex_c_c"
            >
              <img src="@/assets/images/order/praise.png" />价格最优
            </div>
            <el-tooltip
              v-if="row.scope.errorMessage"
              class="item"
              effect="dark"
              :tabindex="2000"
              placement="bottom"
            >
              <div slot="content">
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div
                    style="
                      width: 150px;
                      word-wrap: break-word;
                      word-break: break-all;
                    "
                  >
                    {{ row.scope.errorMessage }}
                  </div>
                  <div
                    style="
                      margin-right: 15px;
                      margin-left: 15px;
                      width: 20px;
                      height: 20px;
                      cursor: pointer;
                    "
                    title="复制"
                    @click="handleTipCopy(row.scope.errorMessage)"
                  >
                    <img src="@/assets/images/order/copy.png" alt="" />
                  </div>
                </div>
              </div>
              <i class="el-icon-warning red" />
            </el-tooltip>
            <i v-else class="el-icon-success green" />
          </template>
          <template slot="express" slot-scope="row">
            {{ row.scope.express }} {{ row.scope.service }}
          </template>
          <template slot="address" slot-scope="row">
            <p
              v-for="(item, $index) in row.scope.address"
              :key="$index"
              class="item-detail"
            >
              <label>{{ item.label }}</label>
              <span>{{ item.value }}</span>
            </p>
          </template>
          <template slot="surcharge" slot-scope="row">
            <p
              v-for="(item, $index) in row.scope.surcharge"
              :key="$index"
              class="item-detail"
            >
              <label>{{ item.label }}</label>
              <span>{{ item.value }}</span>
            </p>
          </template>
          <template slot="surchargeRMB" slot-scope="row">
            <p
              v-for="(item, $index) in row.scope.surchargeRMB"
              :key="$index"
              class="item-detail"
            >
              <label>{{ item.label }}</label>
              <span>{{ item.value }}</span>
            </p>
          </template>
          <template slot="weight" slot-scope="row">
            <p
              v-for="(item, $index) in row.scope.weight"
              :key="$index"
              class="item-detail"
            >
              <label>{{ item.label }}</label>
              <span>{{ item.value }}</span>
            </p>
          </template>
          <template slot="rate" slot-scope="row">
            <p
              v-for="(item, $index) in row.scope.rate"
              :key="$index"
              class="item-detail"
            >
              <label>{{ item.label }}</label>
              <span>{{ item.value }}</span>
            </p>
          </template>
          <template slot="costAmount" slot-scope="row">
            {{ row.scope.costAmount }}
          </template>
          <template slot="opt" slot-scope="row">
            <el-popover placement="left" trigger="hover">
              <ul>
                <li
                  v-for="(a, i) in row.scope.surchargeItems"
                  :key="i"
                  class="detail-item"
                >
                  <span style="margin-left: 16px">{{ a.label }}：</span>
                  <span
                    style="margin-right: 16px"
                    class="detail-money"
                  >{{ a.value }} {{ a.currency }} RMB</span
                  >
                </li>
              </ul>
              <span slot="reference" style="color: #2395f3" class="tips">
                费用明细
              </span>
            </el-popover>
          </template>
        </BaseTable>
      </div>
    </template>
    <CarEnquiryDrawer :drawer="drawer" @close="drawer = false"></CarEnquiryDrawer>
  </div>
</template>

<script>
import { permissions } from '@/utils/common'
const TypeTab = () => import('./components/TypeTab')
const BaseTable = () => import('@/components/table/BaseTable')
import SelectAccountDialog from './components/SelectAccountDialog.vue'
import CarEnquiryDrawer from './components/CarEnquiryDrawer.vue'
import TariffFee from './components/TariffFee.vue'
import { originCity, quotes } from '@/api/fee'
import { getAddressDropdown, getAddressFill } from '@/api/basic'
import { myMixins } from '@/mixins'
export default {
  name: 'FeeTrial',
  components: { TypeTab, SelectAccountDialog, BaseTable, TariffFee, CarEnquiryDrawer },
  mixins: [myMixins],
  data() {
    return {
      typeItems: [
        { name: '本土快递', icon: 'gn-express' },
        { name: '国际快递', icon: 'gj-express' },
        { name: '卡派', icon: 'kapai' }
      ],
      showTypeTab: 0,
      initialData: [],
      purposeData: [],
      addressLine: '',
      showAddRess: 0,
      // 省州
      state: '',
      city: '',
      ruleForm1: {
        initial: '',
        purpose: '',
        postalCode: '',
        residential: false
      },
      ruleForm2: {
        packageNumber: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        unitType: '1'
      },
      rules1: {
        initial: [
          { required: true, message: '请选择始发地', trigger: 'change' }
        ],
        purpose: [
          { required: true, message: '请选择目的地', trigger: 'change' }
        ],
        postalCode: [
          { required: true, message: '请输入收件邮编', trigger: 'blur' }
        ]
      },
      rules2: {
        packageNumber: [
          { required: true, message: '请输入件数', trigger: 'blur' }
        ],
        weight: [
          { required: true, message: '请输入单件实重', trigger: 'blur' }
        ],
        length: [{ required: true, message: '请输入长度', trigger: 'blur' }],
        width: [{ required: true, message: '请输入宽度', trigger: 'blur' }],
        height: [{ required: true, message: '请输入高度', trigger: 'blur' }],
        unitType: [
          { required: true, message: '请选择始单位', trigger: 'change' }
        ]
      },
      // 包裹信息
      packageList: [],
      dialogFormVisible: false,
      // 选择账号数据
      accountsData: {
        quotesType: 0,
        originCityId: null,
        originCountryCode: ''
      },
      // 制单账号ID
      accountIds: [],
      accountsList: [],
      showFeeTabel: false,
      // 以下是估算费用表格数据
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'errorMessage',
            label: '错误信息',
            width: '120px',
            isCustom: true
          },
          {
            filed: 'express',
            label: '派送方式',
            width: '120px',
            isCustom: true
          },
          {
            filed: 'qty',
            label: '件数',
            width: '120px'
          },
          {
            filed: 'accountNumber',
            label: '制单账号',
            width: '140px'
          },
          {
            filed: 'address',
            label: '地址',
            width: '230px',
            isCustom: true
          },
          {
            filed: 'surcharge',
            label: '费用(本位币)',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'surchargeRMB',
            label: '费用(RMB)',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'weight',
            label: '重量',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'rate',
            label: '费率',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'costAmount',
            label: '成本(含燃油、账号使用费)',
            width: '180px',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '180px',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      height: 0,
      drawer: false
    }
  },
  computed: {
    total: function() {
      let total = 0
      this.packageList &&
        this.packageList.forEach((item) => {
          if (item.dimension.unitType === 0) {
            total += Number(item.realWeight.weight) * 0.45
          } else {
            total += Number(item.realWeight.weight)
          }
        })
      return total.toFixed(2)
    }
  },
  created() {
    // 获取始发地数据
    originCity().then((res) => {
      res.data.forEach((item) => {
        this.initialData.push(item)
      })
    })
    // 获取目的地数据
    getAddressDropdown().then((res) => {
      res.forEach((item) => {
        this.purposeData.push(item)
      })
      this.ruleForm1.purpose = this.purposeData[1].code
      this.countryCode = this.purposeData[1].code
    })
  },
  mounted() {
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = ['PS', 'GS']
      this.showTypeTab = codeLits.findIndex((a) => a === menu.children[0].code)
    }
  },
  methods: {
    tabChange(index) {
      this.showTypeTab = index
    },
    flatten(arr) {
      return arr.reduce((pre, item) => {
        return pre.concat(
          item,
          Array.isArray(item.children) ? this.flatten(item.children) : []
        )
      }, [])
    },
    // 侧边栏点击事件
    handleClickTap(typeTab) {
      this.accountsData.quotesType = typeTab.index
      this.showAddRess = typeTab.index
      this.$refs['ruleForm1'].resetFields()
      this.$refs['ruleForm2'].resetFields()
      this.ruleForm1.initial = ''
      this.packageList = []
      this.accountsList = []
    },
    // 始发地事件
    handleChangeInit(value) {
      this.accountIds = []
      this.accountsList = []
      const initialItem = this.flatten(this.initialData)
      initialItem.forEach((item) => {
        if (item.value === value[value.length - 1]) {
          this.accountsData.originCountryCode = item.code
        }
      })
      this.accountsData.originCityId = value[value.length - 1]
    },
    // 输入收件邮编所执行的函数
    querySearchAsync(queryString, cb) {
      // cb为回调函数  回调数据显示在节点上  cb回调的数据必须是数组
      if (!queryString) {
        cb([])
        return
      }
      let data = {
        countryCode: this.ruleForm1.purpose,
        postalCode: this.ruleForm1.postalCode
      }
      if (this.showAddRess === 1) {
        if (!this.ruleForm1.initial) {
          this.$message.error('请选择始发地')
        }
        const initialData = this.flatten(this.initialData)
        const arr = initialData.filter((item) => {
          if (this.ruleForm1.initial[0] === item.value) {
            return item
          }
        })
        data = {
          countryCode: arr[0].label,
          postalCode: this.ruleForm1.postalCode
        }
      }
      getAddressFill(data).then((res) => {
        if (res.success && res.data.length > 0) {
          this.state = res.data[0].provinceCode
          this.city = res.data[0].cityName
          const restaurants = res.data
          const results = queryString
            ? restaurants.filter(this.createFilter(queryString))
            : restaurants
          // 调用 callback 返回建议列表的数据
          cb(results)
        } else {
          cb([])
        }
      })
    },
    // 选择收件邮编执行的事件
    handleSelectPostCode(value) {
      this.ruleForm1.postalCode = value.postalCode
      this.state = value.provinceCode
      this.city = value.cityName
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.postalCode
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    // 添加包裹的事件
    handleAddPack(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const number = Number(this.ruleForm2.packageNumber)
          for (let i = number; i; i--) {
            const packageNumber = this.packageList.length
              ? this.packageList[this.packageList.length - 1].packageNumber + 1
              : this.packageList.length + 1
            this.packageList.push({
              packageNumber,
              remark: '',
              dimension: {
                length: Number(this.ruleForm2.length),
                width: Number(this.ruleForm2.width),
                height: Number(this.ruleForm2.height),
                unitType: Number(this.ruleForm2.unitType)
              },
              realWeight: {
                weight: Number(this.ruleForm2.weight),
                unitType: Number(this.ruleForm2.unitType)
              }
            })
          }
        } else {
          this.$message.error('请将信息填写完整')
          return false
        }
      })
    },
    // 删除包裹
    handleDeleteBack(index) {
      this.packageList.splice(index, 1)
    },
    // 费用估算
    handleFeeCompute(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (!this.packageList.length) {
            this.$refs['ruleForm2'].validate((valid) => {
              if (!valid) {
                this.$message.error('请将信息填写完整')
                return false
              } else {
                this.$message.error('请添加包裹信息')
                return false
              }
            })
            return
          }
          if (!this.accountIds.length) {
            this.$message.error('请选择账号')
            return
          }
          this.loading = true
          const data = {
            quotesType: this.showAddRess,
            postalCode: this.ruleForm1.postalCode,
            state: this.state,
            city: this.city,
            addressLine: this.addressLine,
            originCityId: this.accountsData.originCityId,
            originCountryCode: this.accountsData.originCountryCode,
            accountIds: this.accountIds,
            residential: this.ruleForm1.residential,
            packages: this.packageList
          }
          try {
            const res = await quotes(data)
            this.loading = false
            if (res.success) {
              this.showFeeTabel = true
              this.tableData.columns = res.data
              .sort((a, b) => a.surcharge[0].value - b.surcharge[0].value)
              .sort((a, b) => !!a.errorMessage - !!b.errorMessage)
              this.$nextTick(() => {
                this.height = document.querySelector('.tabel-data').offsetHeight - 123
              })
            } else {
              this.$message.error('请确认信息是否填写完整')
            }
          } catch (error) {
            this.loading = false
            console.error(error)
          }
        } else {
          this.$message.error('请将信息填写完整')
          return false
        }
      })
    },
    // 打开添加账号对话框
    handleAddAccount() {
      if (!this.ruleForm1.initial) {
        this.$message.warning('请选择始发地')
        return
      }
      this.dialogFormVisible = true
    },
    ok(data) {
      const mapData = data.map((item) => item.expressAccountId)
      const arr1 = this.accountsList
      arr1.push(...data)
      // 去重
      const arr2 = [...new Set(arr1.map((v) => JSON.stringify(v)))].map((s) =>
        JSON.parse(s)
      )
      // 再次选择更新数据
      const newArr = arr2.filter((item) =>
        mapData.includes(item.expressAccountId)
      )
      this.accountIds = newArr.map((item) => item.expressAccountId)
      this.accountsList = newArr
      this.dialogFormVisible = false
    },
    cancel() {
      this.dialogFormVisible = false
    },
    handleDeleteAcc(index) {
      this.accountsList.splice(index, 1)
    },
    // 返回估算
    handleBackFee() {
      this.showFeeTabel = false
    },
    // 提示内容复制
    handleTipCopy(val) {
      const url = val
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    },
    resetTabel() {
      this.packageList = []
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 410px;
    overflow: hidden;
    /deep/ .tab-content {
      width: 100% !important;
      margin: 0;
      .tab {
        width: 200px;
      }
    }
    .opt-title {
      height: 24px;
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      margin: 18px 0;
    }
    .typetab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: auto;
      height: 40px;
      background: #f7f7f7;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #eeeeee;
      padding: 5px;
      box-sizing: border-box;
    }
    .typetab-item {
      width: 200px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    .active {
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
      color: #2395f3;
    }
  }
  .data-contanier {
    display: flex;
    // justify-content: space-between;
    .type {
      width: 170px;
      min-width: 170px;
      height: auto;
    }
    .main {
      min-height: 100%;
      height: auto;
      overflow: hidden;
      .row {
        display: flex;
        flex-wrap: wrap;
      }
      .field {
        width: auto;
        .require {
          color: #ff7444;
        }
        label {
          height: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .input {
          width: 320px;
          height: 100%;
        }
        .input2 {
          width: 120px;
          height: 100%;
        }
        .input3 {
          width: 160px;
          height: 100%;
        }
        .switch {
          width: 130px;
          height: 100%;
          /deep/ .el-switch {
            height: 40px;
          }
          /deep/ .el-switch__core {
            position: relative;
            width: 60px !important;
            height: 30px;
            background: #2395f3;
            border-radius: 16px;
            &::after {
              content: "";
              position: absolute;
              top: 2px;
              left: 1px;
              border-radius: 100%;
              transition: all 0.3s;
              width: 24px;
              height: 24px;
              background-color: #fff;
            }
          }
          /deep/ .el-switch.is-checked {
            .el-switch__core {
              &::after {
                margin-left: 32px;
              }
            }
          }
        }
        .btn {
          width: 120px;
          height: 40px;
          background: #FEAA4F;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 40px;
          cursor: pointer;
          text-align: center;
          img {
            vertical-align: sub;
          }
        }
      }
      .el-form-item {
        align-self: flex-end
      }
      .title {
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 19px;
        margin-top: 20px;
        span {
          color: #f76c84;
        }
      }
      .account {
        position: relative;
        width: 240px;
        height: 120px;
        background: #f7f7f7;
        border-radius: 4px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px 0 0 15px;
        box-sizing: border-box;
        .account-img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 40px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
        }
        .account-info1,
        .account-info2 {
          height: 19px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 19px;
          margin-top: 10px;
        }
        .info {
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        .el-icon-remove-outline {
          position: absolute;
          top: 10px;
          right: 10px;
          color: red;
          cursor: pointer;
        }
      }
      .addaccount {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 120px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #eeeeee;
        cursor: pointer;
        .addtext {
          height: 19px;
          font-size: 14px;
          font-weight: 400;
          color: #2395f3;
        }
      }
      .address {
        margin-top: 5px;
        width: 650px;
      }
      .package {
        display: flex;
        justify-content: center;
        width: 980px;
        height: 104px;
        border: 1px solid #eeeeee;
        border-bottom: 0px;
        margin-top: 5px;
        border-radius: 4px 4px 0px 0px;
      }
      .packageList {
        width: 980px;
        height: auto;
        border: 1px solid #eeeeee;
        border-radius: 0px 0px 6px 6px;
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        ul {
          display: inline-block;
          width: 100%;
          li {
            width: 100%;
            display: flex;
            height: 40px;
            .data {
              width: 100%;
              display: flex;
              height: 100%;
              line-height: 40px;
              border-bottom: 1px solid #eeeeee;
              div {
                width: 16.66%;
                height: 100%;
                border-right: 1px solid #eeeeee;
              }
              & div:last-child {
                border-right: 0px;
              }
              .delete {
                color: #2395f3;
                .el-icon-delete {
                  cursor: pointer;
                }
              }
            }
          }
          .header {
            width: 100%;
            height: 40px;
            background: #f7f7f7;
          }
          .packageList-content {
            height: auto;
            max-height: 160px;
            min-height: 160px;
            overflow-y: auto;
          }
          .packageList-content::-webkit-scrollbar {
            width: 10px;
            border: 1px solid #eeeeee;
          }
          .packageList-content::-webkit-scrollbar-thumb {
            background-color: #cdcdcd;
            border: 2px solid transparent;
            border-radius: 50px;
            background-clip: content-box;
          }
        }
      }
      .sum {
        width: 100%;
        height: 40px;
        background: #f7f7f7;
        border: 1px solid #eeeeee;
        border-top: 0;
        border-bottom: 0;
        border-left: 0;
        font-size: 14px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        margin-top: -4px;
        .sum-no {
          color: red;
        }
      }
      .fee {
        display: flex;
        justify-content: center;
        width: 980px;
        .feeBtn {
          width: 240px;
          height: 40px;
          background: #2395f3;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          border: 0;
          color: #ffffff;
          margin-top: 40px;
        }
      }
    }
  }
  .tabel-data {
    width: calc(100% - 20px);
    height: 100%;

    .red {
      color: #fe453d;
    }
    .green {
      color: #33c481;
    }
    .icon {
      font-size: 24px;
    }
    .item-detail {
      width: 100%;
      height: auto;
      text-align: left;
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      & + .item-detail {
        margin-top: 5px;
      }
    }
    .item-detail label {
      width: 80px;
      display: inline-block;
      background: #f7f7f7;
      text-align: center;
    }
    .item-detail span {
      margin-left: 5px;
      font-weight: bold;
    }
    .back-fee {
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      padding-left: 33px;
      box-sizing: border-box;
      .back {
        height: 21px;
        font-size: 16px;
        font-weight: 400;
        color: #2395f3;
        line-height: 21px;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
      .current {
        height: 21px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 21px;
        margin-left: 10px;
        i {
          margin-right: 10px;
        }
      }
    }
  }
  .optimal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 30px;
    background: #333333;
    border-radius: 0px 0px 15px 0px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #feaa4f;
    img {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
  }
}
.pad {
  padding-left: 120px;
}
</style>
