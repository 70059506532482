<template>
  <div class="contanier">
    <div class="form-contanier">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <div class="row">
          <div class="field">
            <div class="input">
              <label><span class="require">* </span>运输模式</label>
              <div class="tab">
                <div v-for="(item, $index) in tabItems" :key="$index" :class="['panel', active === $index + 1 ? 'active' : '']" @click="handleClickTab(item, $index + 1)">
                  <svg-icon className="icon" :iconClass="item.icon" />
                  <span>{{ item.name }}</span>
                  <span class="round"><img src="@/assets/images/order/selected.png" alt=""></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <div class="input">
              <label><span class="require">* </span>税号</label>
              <el-form-item prop="taxNumber">
                <el-input
                  v-model="ruleForm.taxNumber"
                  placeholder="请输入税号"
                  maxlength="10"
                  minlength="6"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <div class="input">
              <label><span class="require">* </span>产品总价</label>
              <el-form-item prop="price">
                <el-input
                  v-model="ruleForm.price"
                  placeholder="请输入产品总价"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="btn-content">
          <el-button class="fee-btn" @click="submitForm('ruleForm')">费用估算</el-button>
          <div class="tool-btn">
            <el-tooltip
              content="单次导入不得超过20条"
              effect="dark"
              :tabindex="2000"
              placement="bottom"
            >
              <el-upload action="#" accept=".xls,.xlsx,.csv" :http-request="handleUpLoad" :show-file-list="false">
                <el-button class="up-btn"><img style="margin-right: 5px;" src="@/assets/images/order/upload.png" alt=""> 导入</el-button>
              </el-upload>
            </el-tooltip>
            <a id="down" class="down-btn" download="关税试算模板.xlsx"><img src="@/assets/images/order/download.png" alt=""> 下载模板</a>
          </div>
        </div>
      </el-form>
    </div>
    <div class="tabel-contanier">
      <div class="tabel-top">估算结果</div>
      <BaseTable
        v-if="height !== 0"
        id="exportTabel"
        :border="true"
        :height="height"
        :isPager="false"
        :tableData="tableData"
        :loading="loading"
      >
        <template slot="productName" slot-scope="row">
          <template v-if="row.scope.calSuccess">
            {{ row.scope.productName }}
          </template>
          <template v-else>
            <div class="message">
              <div class="failure"><img style="margin-right: 6px;" src="@/assets/images/order/failure.png" alt=""> 计算失败</div>
              <div style="font-size: 14px;">{{ row.scope.message }}</div>
            </div>
          </template>
        </template>
        <template slot="baseRate" slot-scope="row">
          {{ row.scope.baseRate }}%
        </template>
        <template slot="addRate" slot-scope="row">
          {{ row.scope.addRate }}%
        </template>
        <template slot="taxes" slot-scope="row">
          <div class="taxes">
            <img style="margin-right: 5px;" src="@/assets/images/order/usd.png" alt="">
            {{ row.scope.taxes }}&nbsp;USD
          </div>
        </template>
      </BaseTable>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'
const BaseTable = () => import('@/components/table/BaseTable')
import { getTariffCalculate, importTariffCalculateExcel } from '@/api/fee'
import { downLoadTemplate } from '@/api/system'
export default {
  components: { SvgIcon, BaseTable },
  data() {
    return {
      tabItems: [
        { name: '海运', icon: 'ship' },
        { name: '空运', icon: 'plane' }
      ],
      active: 1,
      ruleForm: {
        taxNumber: '',
        price: ''
      },
      rules: {
        taxNumber: [
          { required: true, message: '请输入税号', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入产品总价', trigger: 'blur' }
        ]
      },
      // 以下是估算费用表格数据
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'taxNumber',
            label: '税号',
            width: '140px'
          },
          {
            filed: 'productName',
            label: '品名',
            width: '478px',
            isCustom: true
          },
          {
            filed: 'baseRate',
            label: '基础税率',
            width: '140px',
            isCustom: true
          },
          {
            filed: 'addRate',
            label: '加征税率',
            width: '140px',
            isCustom: true,
            tip: true,
            tipContent: '仅支持加征批次为99038801，99038802，99038803，99038804，99038815，99038866 的加征税率计算'
          },
          {
            filed: 'taxes',
            label: '税金',
            width: '180px',
            tip: true,
            tipContent: '时效基于FEEPOWER大数据中心，实时更新，仅供参考',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      height: 0
    }
  },
  mounted() {
    this.height = document.querySelector('.el-form').offsetHeight + 60
  },
  async created() {
    const result = await downLoadTemplate('70b1c4134c8ea9e43619e06e9e952d86')
    const blob = new Blob([result.data], { type: 'application/vnd.ms-excel' })
    const a = document.getElementById('down')
    a.href = URL.createObjectURL(blob)
  },
  methods: {
    handleClickTab(item, index) {
      this.active = index
      this.$emit('handleClick', {
        item,
        index
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(async(valid) => {
          if (valid) {
            if (this.ruleForm.taxNumber.length < 6) {
              this.$message.error('税号长度最小6位')
              return
            }
            this.loading = true
            try {
              const data = {
                ...this.ruleForm,
                type: this.active
              }
              const result = await getTariffCalculate(data)
              if (result.success) {
                this.tableData.columns = [result.data]
                this.loading = false
              } else {
                this.$message.error(result.errorMessage)
                this.loading = false
              }
            } catch (error) {
              console.error(error)
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
    },
    async handleUpLoad(file) {
      // new FormData()会自动将请求头Content-type  转换为multipart/form-data
      this.loading = true
      const formdata = new FormData()
      formdata.append('file', file.file, file.name)
      formdata.append('type', this.active)
      const result = await importTariffCalculateExcel(formdata)
      if (result.data.success) {
        this.tableData.columns = result.data.data
        this.$message({
          message: '导入成功',
          type: 'success'
        })
        this.loading = false
      } else {
        this.$message.error(result.errorMessage)
        this.loading = false
      }
      // 使用new FileReader()文件读取器将上传文件请求到的数据进行转换
      // const reader = new FileReader()
      // reader.readAsText(result.data, 'utf-8')
      // // 转换的过程中是一个异步的过程  需要通过onload等待事件的完毕  来获取最终想要的数据
      // reader.onload = function() {
      //   const readerres = this.result
      //   // 将数据转换成JSON对象
      //   const parseObj = JSON.parse(readerres)
      //   // 上传成功
      //   if (parseObj.success) {
      //     _this.tableData.columns = parseObj.data
      //     _this.$message({
      //       message: '导入成功',
      //       type: 'success'
      //     })
      //   } else {
      //     _this.$message.error(parseObj.errorMessage)
      //   }
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.contanier {
  display: flex;
  width: 100%;
}

.form-contanier {
  width: 410px;
  margin-right: 100px;
  .row {
      display: flex;
      flex-wrap: wrap;
  }
  .field {
    margin-top: 24px;
    height: 68px;
    width: 100%;
    .require {
      color: #ff7444;
    }
    label {
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #25396f;
      line-height: 24px;
    }
    .input {
      width: 100%;
      height: 100%;
    }
    .tab {
      display: flex;
      width: 100%;
    }
    .panel {
      display: flex;
      align-items: center;
      position: relative;
      width: 200px;
      height: 40px;
      background: #FFFFFF;
      color: #999999;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #EEEEEE;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      .round {
        position: absolute;
        right: 20px;
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #CDCDCD;
      }
      .icon {
        margin-left: 20px;
        margin-right: 5px;
        font-size: 20px;
      }
    }
    .panel:first-child {
      margin-right: 10px;
    }
    .active {
      border: 1px solid #2395F3;
      color: #2395F3;
      .round {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2395F3;
        border: 0;
      }
    }
  }
  .btn-content {
    display: flex;
    flex-direction: column;
    width: 270px;
    height: auto;
    margin: 60px auto 0;
    .fee-btn {
      width: 270px;
      height: 40px;
      background: #2395F3;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
      border: 0;
      color: #FFFFFF;
      border: 1px solid #2395F3;
    }
    .tool-btn {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
    }
    /deep/ .el-upload {
      width: 120px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
      background: #35BA83;
      border: 1px solid #35BA83;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        background: #35BA83;
        border: 1px solid #35BA83;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .down-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      cursor: pointer;
      border: 1px solid #35BA83;
      color: #35BA83;
      font-size: 14px;
      font-weight: 400;
      background: #FFFFFF;
      /deep/ span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.tabel-contanier {
  width: 1080px;
  margin-top: 46px;
  .tabel-top {
    width: 1080px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    border: 1px solid #EEEEEE;
    border-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: #2395F3;
  }
  /deep/ .fTable {
    .fp-cell {
      border-right: 0;
    }
  }
  .taxes {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #FE453D;
  }
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FE453D;
    .failure {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 30px;
      background: rgba(254, 69, 61, 0.1);
      border-radius: 4px 4px 4px 4px;
      margin-right: 10px;
      font-size: 12px;
    }
  }
}
</style>
