var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contanier" }, [
    _c(
      "div",
      { staticClass: "form-contanier" },
      [
        _c(
          "el-form",
          { ref: "ruleForm", attrs: { model: _vm.ruleForm, rules: _vm.rules } },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "field" }, [
                _c("div", { staticClass: "input" }, [
                  _c("label", [
                    _c("span", { staticClass: "require" }, [_vm._v("* ")]),
                    _vm._v("运输模式"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "tab" },
                    _vm._l(_vm.tabItems, function (item, $index) {
                      return _c(
                        "div",
                        {
                          key: $index,
                          class: [
                            "panel",
                            _vm.active === $index + 1 ? "active" : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.handleClickTab(item, $index + 1)
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            attrs: { className: "icon", iconClass: item.icon },
                          }),
                          _c("span", [_vm._v(_vm._s(item.name))]),
                          _c("span", { staticClass: "round" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/order/selected.png"),
                                alt: "",
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "field" }, [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("label", [
                      _c("span", { staticClass: "require" }, [_vm._v("* ")]),
                      _vm._v("税号"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "taxNumber" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入税号",
                            maxlength: "10",
                            minlength: "6",
                          },
                          model: {
                            value: _vm.ruleForm.taxNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "taxNumber", $$v)
                            },
                            expression: "ruleForm.taxNumber",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "field" }, [
                _c(
                  "div",
                  { staticClass: "input" },
                  [
                    _c("label", [
                      _c("span", { staticClass: "require" }, [_vm._v("* ")]),
                      _vm._v("产品总价"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "price" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品总价",
                            oninput: "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                          },
                          model: {
                            value: _vm.ruleForm.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "price", $$v)
                            },
                            expression: "ruleForm.price",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "btn-content" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "fee-btn",
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("费用估算")]
                ),
                _c(
                  "div",
                  { staticClass: "tool-btn" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "单次导入不得超过20条",
                          effect: "dark",
                          tabindex: 2000,
                          placement: "bottom",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            attrs: {
                              action: "#",
                              accept: ".xls,.xlsx,.csv",
                              "http-request": _vm.handleUpLoad,
                              "show-file-list": false,
                            },
                          },
                          [
                            _c("el-button", { staticClass: "up-btn" }, [
                              _c("img", {
                                staticStyle: { "margin-right": "5px" },
                                attrs: {
                                  src: require("@/assets/images/order/upload.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" 导入"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "down-btn",
                        attrs: { id: "down", download: "关税试算模板.xlsx" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/order/download.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" 下载模板"),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "tabel-contanier" },
      [
        _c("div", { staticClass: "tabel-top" }, [_vm._v("估算结果")]),
        _vm.height !== 0
          ? _c("BaseTable", {
              attrs: {
                id: "exportTabel",
                border: true,
                height: _vm.height,
                isPager: false,
                tableData: _vm.tableData,
                loading: _vm.loading,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "productName",
                    fn: function (row) {
                      return [
                        row.scope.calSuccess
                          ? [_vm._v(" " + _vm._s(row.scope.productName) + " ")]
                          : [
                              _c("div", { staticClass: "message" }, [
                                _c("div", { staticClass: "failure" }, [
                                  _c("img", {
                                    staticStyle: { "margin-right": "6px" },
                                    attrs: {
                                      src: require("@/assets/images/order/failure.png"),
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" 计算失败"),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "14px" } },
                                  [_vm._v(_vm._s(row.scope.message))]
                                ),
                              ]),
                            ],
                      ]
                    },
                  },
                  {
                    key: "baseRate",
                    fn: function (row) {
                      return [_vm._v(" " + _vm._s(row.scope.baseRate) + "% ")]
                    },
                  },
                  {
                    key: "addRate",
                    fn: function (row) {
                      return [_vm._v(" " + _vm._s(row.scope.addRate) + "% ")]
                    },
                  },
                  {
                    key: "taxes",
                    fn: function (row) {
                      return [
                        _c("div", { staticClass: "taxes" }, [
                          _c("img", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: {
                              src: require("@/assets/images/order/usd.png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" " + _vm._s(row.scope.taxes) + " USD "),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                70749544
              ),
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }