<template>
  <Drawer :drawer="drawer" :customTitle="result" title="卡派询价" :size="size" @close="close">
    <div v-if="result" slot="title" class="backHeader">
      <div class="back" @click="back">
        <img src="@/assets/images/order/back.png" />返回重新估算
      </div>
    </div>
    <div v-loading="loading" class="view">
      <template v-if="!result">
        <base-panel
          class="panel"
          :showLine="false"
          :showIcon="false"
          :panelContentSty="{ width: 'calc(100% - 40px)', margin: '0 auto' }"
          :topContentSty="{ width: 'calc(100% - 40px)', margin: '0 auto' }"
        >
          <template slot="header">填写卡派信息</template>
          <div class="panle-container">
            <div class="row">
              <div class="field">
                <div class="input">
                  <label>目的国家</label>
                  <div class="widthMax">
                    <el-select
                      v-model="addressInfo.countryCode"
                      style="width: 320px"
                    >
                      <el-option
                        v-for="item in addressDropCodeOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="input">
                  <label>可提货日期</label>
                  <div class="widthMax">
                    <el-date-picker
                      v-model="pickUpDate"
                      align="right"
                      type="date"
                      style="width: 320px"
                      placeholder="请选择日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="field">
                <div class="input">
                  <label>运输模式</label>
                  <div class="widthMax">
                    <el-select v-model="carPadInfo.yunshu" style="width: 320px">
                      <el-option
                        v-for="item in yunshuOptions"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="input">
                  <label
                  ><el-checkbox
                    v-model="
                      addressInfo.specialRequirement.residentialNonCommercial
                    "
                    label="住宅位置交付"
                  ></el-checkbox
                  ><el-checkbox
                    v-model="addressInfo.specialRequirement.insideDelivery"
                    label="内部交货"
                  ></el-checkbox
                  ></label>
                  <div class="widthMax2">
                    <el-checkbox
                      v-model="addressInfo.specialRequirement.liftGate"
                      label="升降门交货"
                    ></el-checkbox
                    ><el-checkbox
                      v-model="addressInfo.specialRequirement.limitedAccess"
                      label="有限访问交付"
                    ></el-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-panel>
        <base-panel
          class="panel"
          :showLine="true"
          :showIcon="false"
          :panelContentSty="{ width: 'calc(100% - 40px)', margin: '0 auto' }"
          :topContentSty="{ width: 'calc(100% - 40px)', margin: '0 auto' }"
        >
          <template slot="header">地址信息：目的地</template>
          <div class="panle-container">
            <div class="row">
              <div class="field">
                <div class="input">
                  <label>省州</label>
                  <div class="widthMax">
                    <el-input
                      v-model="addressInfo.stateProvinceCode"
                      placeholder="请输入省州"
                      style="width: 320px"
                    >
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="input">
                  <label>城市</label>
                  <div class="widthMax">
                    <el-input
                      v-model="addressInfo.city"
                      placeholder="请输入城市"
                      style="width: 320px"
                    >
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" style="width: 100%">
              <div class="field" style="width: 100%">
                <div class="input" style="width: 100%">
                  <label>详细地址</label>
                  <div class="widthMax">
                    <el-input
                      v-model="addressInfo.address1"
                      placeholder="请输入详细地址"
                    >
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="field">
                <div class="input">
                  <label>邮编</label>
                  <div class="widthMax">
                    <el-autocomplete
                      v-model="addressInfo.postalCode"
                      style="width: 320px"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      :fetch-suggestions="querySearchAsync"
                      :trigger-on-focus="false"
                      placeholder="请输入邮编"
                      @select="handleSelect"
                    >
                      <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                        <span class="addr">{{ item.address }}</span>
                      </template>
                    </el-autocomplete>
                  </div>
                </div>
              </div>
              <div class="address-check">
                <div class="btn" @click="checkAddress">
                  <img src="@/assets/images/order/addressIcon.png" />
                  地址校验
                </div>
                <div v-if="addressText" class="check-res">
                  校验结果：{{ addressText }}
                </div>
              </div>
            </div>
          </div>
        </base-panel>
        <base-panel
          class="panel"
          :showLine="true"
          :showIcon="false"
          :panelContentSty="{ width: 'calc(100% - 40px)', margin: '0 auto' }"
          :topContentSty="{ width: 'calc(100% - 40px)', margin: '0 auto' }"
        >
          <template slot="header">货物信息</template>
          <template slot="opt">
            <div class="newadd flex_c_c" @click="newAdd">
              <img src="@/assets/images/order/addimg.png" alt="" />
              新增
            </div>
          </template>
          <div class="panle-container">
            <div
              v-for="(item, $index) in goodsInfo"
              :key="$index"
              :class="['goods-item', $index ? 'border-item' : '']"
            >
              <div
                v-if="$index"
                class="delete-goods flex_c_c"
                title="删除"
                @click="deleteGoodsInfo($index)"
              >
                <i class="el-icon-delete"></i>
              </div>
              <div class="row">
                <div class="field">
                  <div class="input">
                    <label>包装类型</label>
                    <div class="widthMax">
                      <el-select
                        v-model="item.packingCode"
                        placeholder="请选择包装类型"
                        style="width: 320px"
                      >
                        <el-option
                          v-for="keys in packagingOptions"
                          :key="keys.value"
                          :label="keys.label"
                          :value="keys.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="input">
                    <label>卡板型号</label>
                    <div class="widthMax">
                      <el-select
                        v-model="item.kabanVal"
                        placeholder="请选择卡板型号"
                        style="width: 320px"
                        @change="handleSelectKb($index, item.kabanVal)"
                      >
                        <el-option
                          v-for="keys in kabanOptions"
                          :key="keys.value"
                          :label="keys.label"
                          :value="keys.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="field">
                  <div class="input">
                    <label>尺寸</label>
                    <div class="widthMax">
                      <el-input
                        v-model="item.length"
                        placeholder="长"
                        class="input1"
                        :disabled="item.kabanVal === '自定义' ? false : true"
                        oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        style="width: 94px"
                      >
                      </el-input>
                      <el-input
                        v-model="item.width"
                        placeholder="宽"
                        class="input1 input2"
                        :disabled="item.kabanVal === '自定义' ? false : true"
                        oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        style="width: 93px"
                      >
                      </el-input>
                      <el-input
                        v-model="item.height"
                        placeholder="高"
                        class="input2"
                        oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        style="width: 133px"
                      >
                        <div slot="suffix" class="suffix">IN</div>
                      </el-input>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="input">
                    <label>总重量</label>
                    <div class="widthMax">
                      <el-input
                        v-model="item.totalWeight"
                        placeholder="请输入单重"
                        oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        style="width: 320px"
                      >
                        <div slot="suffix" class="suffix">LB</div>
                      </el-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="field">
                  <div class="input">
                    <label>数量</label>
                    <div class="widthMax">
                      <el-input
                        v-model="item.qty"
                        placeholder="请输入数量"
                        oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                        style="width: 320px"
                      >
                      </el-input>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="input">
                    <label>货运等级</label>
                    <div class="widthMax2">
                      <div class="calculate" @click="handleGoodsClass($index)">
                        <img src="@/assets/images/order/calculate.png" alt="" />
                        计算货运等级
                      </div>
                      <div v-show="item.freightClass" class="goods-class">
                        Freight Class：{{ item.freightClass }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </base-panel>
      </template>
      <template v-if="result">
        <div class="result-text">估算结果</div>
        <div
          v-for="(item, $index) in resultData"
          :key="$index"
          :class="['result-item', $index === 0 ? 'active' : '']"
        >
          <div v-if="$index === 0" class="optimal">最优选择</div>
          <div class="module gys">
            <div class="mdoe-title">
              <img src="@/assets/images/order/gong.png" />&nbsp;供应商
            </div>
            <div class="mdoe-info" style="color: #666666">
              {{ item.carrier.carrierName }}
            </div>
          </div>
          <div class="module m-border">
            <div class="mdoe-title">
              <img
                src="@/assets/images/order/date.png"
                alt=""
              />&nbsp;预计到达天数
            </div>
            <div class="mdoe-info" style="color: #33c481">
              {{ item.transit.minimumTransitDays }} 天
            </div>
          </div>
          <div class="module m-border">
            <div class="mdoe-title">
              <img
                src="@/assets/images/order/yunshu.png"
                alt=""
              />&nbsp;运输模式
            </div>
            <div class="mdoe-info" style="color: #2395f3">
              {{ item.transportModeType }}
            </div>
          </div>
          <div class="module1">
            <div class="mdoe-title">
              预估费用&nbsp;
              <el-popover trigger="hover" popper-class="modulePop" width="380" placement="bottom">
                <div>
                  <div class="tabel">
                    <div class="trth">
                      <div class="th1">费用名称</div>
                      <div class="th2">金额</div>
                    </div>
                    <div
                      v-for="(keys, index) in item.rates"
                      :key="index"
                      class="trtd"
                    >
                      <div class="td1">{{ keys.rateCodeValue }}</div>
                      <div class="td2">${{ keys.totalRate }}</div>
                    </div>
                  </div>
                </div>
                <img
                  slot="reference"
                  src="@/assets/images/table/question.png"
                  width="14"
                  height="14"
                /></el-popover>
            </div>
            <div class="mdoe-info" style="color: #fe453d">
              $ {{ item.totalCharge }}
            </div>
            <div v-if="$index === 0" class="lowest">最低</div>
          </div>
        </div>
      </template>
      <div v-if="!result" class="footer flex_c_c">
        <div class="ok flex_c_c" @click="feeResult">费用估算</div>
      </div>
    </div>
  </Drawer>
</template>

<script>
const BasePanel = () => import('@/components/panel/BasePanelForm.vue')
const Drawer = () => import('@/components/drawer/Drawer')
import { getAddressFill } from '@/api/basic'
import { addressValidation, getTruckRate } from '@/api/fee'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
export default {
  components: { Drawer, BasePanel },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      addressText: '',
      carPadInfo: {
        yunshu: 'LTL'
      },
      addressInfo: {
        countryCode: 'US',
        stateProvinceCode: '',
        city: '',
        address1: '',
        postalCode: '',
        specialRequirement: {
          liftGate: false,
          insideDelivery: false,
          residentialNonCommercial: false,
          limitedAccess: false,
          insidePickup: false,
          tradeShoworConvention: false,
          constructionSite: false,
          dropOffAtCarrierTerminal: false,
          pickupAtCarrierTerminal: false
        }
      },
      goodsInfo: [
        {
          packingCode: '',
          length: '',
          width: '',
          height: '',
          totalWeight: '',
          qty: '',
          freightClass: '',
          weightUnit: 'Pounds',
          linearUnit: 'Inches',
          kabanVal: '自定义'
        }
      ],
      transportModes: [{ mode: 'LTL' }, { mode: 'TL' }],
      pickUpDate: '',
      result: false,
      packagingOptions: [
        {
          value: 'PLT',
          label: '板'
        }
      ],
      yunshuOptions: [{ label: 'LTL' }],
      kabanOptions: config.palletOptions,
      resultData: [],
      loading: false,
      size: 710
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    addressDropCodeOption() {
      return (
        this.addressDropOption &&
        this.addressDropOption.map((item) => {
          return {
            value: item.code,
            label: `${item.code} - ${item.label}`
          }
        })
      )
    }
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    close() {
      this.size = 710
      this.result = false
      this.$emit('close')
    },
    newAdd() {
      const goodsinfo = {
        packingCode: '',
        length: '',
        width: '',
        height: '',
        totalWeight: '',
        qty: '',
        freightClass: '',
        weightUnit: 'Pounds',
        linearUnit: 'Inches',
        kabanVal: '自定义'
      }
      this.goodsInfo.push(goodsinfo)
    },
    deleteGoodsInfo(index) {
      this.goodsInfo.splice(index, 1)
    },
    handleGoodsClass(index) {
      const { length, width, height, totalWeight, qty } = this.goodsInfo[index]
      if (
        length !== '' &&
        width !== '' &&
        height !== '' &&
        totalWeight !== '' &&
        qty !== ''
      ) {
        const tiji =
          Number(length) *
          0.0833333 *
          (Number(width) * 0.0833333) *
          (Number(height) * 0.0833333)
        const zhongliang = (Number(totalWeight) * 2.2046226) / qty
        const density = Number(zhongliang) / Number(tiji)
        this.goodsInfo[index].freightClass = this.resultClass(density)
      } else {
        this.$message.warning('请填写完整当前货物信息')
      }
    },
    resultClass(density) {
      const describeForNameMap = [
        [(density) => density < 3, () => 300],
        [(density) => density < 4, () => 250],
        [(density) => density < 5, () => 200],
        [(density) => density < 6, () => 175],
        [(density) => density < 7, () => 150],
        [(density) => density < 8, () => 125],
        [(density) => density < 9, () => 110],
        [(density) => density < 10.5, () => 100],
        [(density) => density < 12, () => 92.5],
        [(density) => density < 13.5, () => 85],
        [(density) => density < 15, () => 77.5],
        [(density) => density < 22.5, () => 70],
        [(density) => density < 30, () => 65],
        [(density) => density < 35, () => 60],
        [(density) => density < 50, () => 55]
      ]
      let str // 存储判断结果
      const getDescribe = describeForNameMap.find((item) => item[0](density))
      if (getDescribe) {
          str = getDescribe[1]()
      } else {
          str = 50
      }
      return str
    },
    feeResult() {
      this.goodsInfo.forEach((a, i) => {
        this.handleGoodsClass(i)
      })
      if (
        !this.addressInfo.stateProvinceCode ||
        !this.addressInfo.city ||
        !this.addressInfo.address1 ||
        !this.addressInfo.postalCode ||
        !this.carPadInfo.yunshu ||
        !this.pickUpDate
      ) {
        this.$message.warning('请填写完整信息')
        return
      }
      let flag = false
      this.goodsInfo.forEach((item) => {
        const itemVal = Object.values(item)
        itemVal.forEach((v) => {
          if (!v) {
            flag = true
          }
        })
      })
      if (flag) {
        this.$message.warning('请填写完整信息')
        return
      }
      this.getList()
    },
    getList() {
      this.loading = true
      try {
        const params = {
          items: this.goodsInfo,
          destination: this.addressInfo,
          pickUpDate: this.pickUpDate,
          transportModes: this.transportModes
        }
        getTruckRate(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.size = 950
            this.result = true
            // 筛选最优
            this.resultData = res.data.quoteSummaries.sort((a, b) =>
              a.totalCharge < b.totalCharge
                ? -1
                : a.totalCharge > b.totalCharge
                ? 1
                : 0
            )
          } else {
            this.$message.error(res.errorMessage)
            this.loading = false
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    back() {
      this.size = 710
      this.result = false
    },
    async checkAddress() {
      if (
        !(
          this.addressInfo.countryCode &&
          this.addressInfo.stateProvinceCode &&
          this.addressInfo.postalCode &&
          this.addressInfo.city &&
          this.addressInfo.address1
        )
      ) {
        this.$message.warning('信息不完整，请填写国家、省州、城市、邮编和地址')
        return
      }
      try {
        const res = await addressValidation({
          ...this.addressInfo,
          addressLine1: this.addressInfo.address1,
          state: this.addressInfo.stateProvinceCode
        })
        if (res.success) {
          this.addressText = config.classificationOptions.find(
            (a) => a.value === res.data.classification
          ).label
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    handleSelect(item) {
      const city = item.address.split(',')[0]
      const provinceName = item.address.split(',')[1]
      this.addressInfo.stateProvinceCode = provinceName
      this.addressInfo.city = city
    },
    async querySearchAsync(queryString, cb) {
      try {
        const res = await getAddressFill({
          countryCode: this.addressInfo.countryCode,
          postalCode: queryString
        })
        if (res.success) {
          const arr = res.data.map((item) => {
            return {
              value: item.postalCode,
              address: `${item.cityName}, ${item.provinceCode},${item.postalCode}`
            }
          })
          cb(arr)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSelectKb(index, value) {
      if (value !== '自定义') {
        const values = value.split('*')
        this.goodsInfo[index].length = values[0]
        this.goodsInfo[index].width = values[1]
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-loading-mask {
  height: 120%;
}
/deep/ .el-input__suffix {
  right: 0;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}
/deep/ .tabel {
  width: 340px;
  border-radius: 6px 6px 6px 6px;
  margin: 0 auto;
  overflow: hidden;
  .trth {
    display: flex;
    background: #f7f7f7;
    border-right: 1px solid #EEEEEE;
    .th1,
    .th2 {
      height: 40px;
      border: 1px solid #EEEEEE;
      border-right: 0;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      line-height: 40px;
      box-sizing: border-box;
    }
    .th1 {
      width: 200px;
    }
    .th2 {
      width: 140px;
    }
  }
  .trtd {
    display: flex;
    background: #ffffff;
    border-right: 1px solid #EEEEEE;
    .td1,
    .td2 {
      height: 40px;
      border: 1px solid #EEEEEE;
      border-right: 0;
      border-top: 0;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      line-height: 40px;
    }
    .td1 {
      width: 200px;
    }
    .td2 {
      width: 140px;
      color: #2395f3;
    }
  }
}
.backHeader {
  .back {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #2395F3;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
.view {
  width: 100%;
  height: 100%;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  box-sizing: border-box;
  overflow-x: hidden;
  padding-bottom: 100px;
  box-sizing: border-box;
  .result-text {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin: 20px 0 20px 20px;
  }
  .result-item {
    position: relative;
    display: flex;
    width: calc(100% - 40px);
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
    border-radius: 4px;
    margin: 0 auto 20px;
    padding: 16px 16px 16px 0;
    box-sizing: border-box;
    .module {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 176px;
      height: 100%;
    }
    .module1 {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 100%;
      background: #F7F7F7;
      border-radius: 4px;
    }
    .m-border {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 40px;
        border: 1px solid #f2f7ff;
      }
    }
    .mdoe-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 19px;
      line-height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .mdoe-info {
      height: 24px;
      font-size: 18px;
      font-weight: bold;
      margin-top: 16px;
    }
    .gys {
      width: 350px;
    }
    .optimal {
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #2395F3;
      border-radius: 4px 0px 8px 0px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
    }
    .lowest {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #333333;
      border-radius: 0px 4px 0px 6px;
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  .active {
    border: 1px solid #2395f3;
    box-shadow: 0 0 0 0;
  }
  .panel {
    width: 100%;
    &:first-child {
      margin-top: 15px;
    }
    & + .panel {
      margin-top: 30px;
    }
    .newadd {
      cursor: pointer;
      width: 84px;
      height: 30px;
      background: #35BA83;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      img {
        margin-right: 4px;
      }
    }
  }
  .panle-container {
    width: 100%;
    height: auto;
    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .address-check {
        width: 320px;
        margin-top: 34px;
        height: 40px;
        opacity: 1;
        display: flex;
        justify-content: space-between;
        .btn {
          width: 120px;
          height: 40px;
          background: #33c481;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
        }
        img {
          margin: 10px 0;
        }
        .check-res {
          width: 184px;
          height: 40px;
          background: rgba(51, 196, 129, 0.1);
          border-radius: 4px 4px 4px 4px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #33c481;
          line-height: 40px;
          text-align: center;
        }
      }
    }
    .field {
      margin-top: 10px;
      width: auto;
      .require {
        color: #ff7444;
      }
      label {
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .input {
        width: 320px;
        height: 100%;
      }
      /deep/ .input1 {
        input {
          border-right: 0px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
      /deep/ .input2 {
        input {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
      .suffix {
        width: 40px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #F7F7F7;
        border-radius: 0px 4px 4px 0px;
        border-left: 1px solid #EEEEEE;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-top: 1px;
        margin-right: 1px;
        box-sizing: border-box;
      }
      .widthMax {
        width: 100%;
      }
      .widthMax2 {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        .calculate {
          display: flex;
          align-items: center;
          height: 19px;
          font-size: 14px;
          font-weight: 400;
          color: #2395f3;
          cursor: pointer;
          img {
            margin-right: 4px;
          }
        }
        .goods-class {
          width: 184px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: rgba(35, 149, 243, 0.1);
          border-radius: 4px 4px 4px 4px;
          font-size: 14px;
          font-weight: 400;
          color: #2395f3;
          margin-left: 32px;
        }
      }
    }
    .goods-item {
      position: relative;
      width: 100%;
      padding-top: 20px;
      box-sizing: border-box;
      .delete-goods {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        background: #2395F3;
        border-radius: 0px 0px 4px 4px;
        color: #ffffff;
        cursor: pointer;
      }
      & + .goods-item {
        margin-top: 30px;
      }
    }
    .border-item {
      &::after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -20px;
        width: 710px;
        height: 1px;
        background: #F7F7F7;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 710px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.el-form-item {
  margin-bottom: 0;
}
/deep/ .el-drawer__body {
  overflow: auto;
}
</style>
<style>
.modulePop {
  box-sizing: border-box;
}
</style>
