<template>
  <el-dialog
    v-drag
    title="选择账号"
    :visible.sync="dialogFormVisible"
    width="380px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
  >
    <div class="content">
      <div class="checkbox">
        <el-checkbox-group v-model="checkList">
          <div v-for="item in accountsList" :key="item.expressAccountId" class="card">
            <div class="card-img flex_c_c">
              <img :src="require(`@/assets/images/order/${item.expressSupplier.toLowerCase()}.png`)" alt="">
            </div>
            <div class="card-info1">账&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 号：<span class="info">{{ item.accountNumber }}</span></div>
            <div class="card-info2">服务类型：<span class="info">{{ item.expressService }}</span></div>
            <el-checkbox :label="item.expressAccountId"></el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
    </div>
    <div class="check-btn">
      <button class="btn" @click="handleAllSelect">全选</button>
      <button class="btn" @click="handleReverse">反选</button>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取消</div>
      <div class="ok flex_c_c" @click="ok">确定</div>
    </span>
  </el-dialog>
</template>
<script>
import { availableAccounts } from '@/api/fee'
export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    accountsData: {
      type: Object,
      require: true
    },
    accountsListA: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      accountsList: [],
      checkList: []
    }
  },
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.getAvailableAccounts()
      }
    }
  },
  methods: {
    cancel() {
      this.checkList = []
      this.$emit('cancel')
    },
    ok() {
      const arr = this.accountsList.filter(item => {
        return this.checkList.includes(item.expressAccountId)
      })
      if (arr.length > 10) {
        this.$message.error('最多只能选择10个账号')
        return
      }
      this.checkList = []
      this.$emit('ok', arr)
    },
    getAvailableAccounts() {
      availableAccounts(this.accountsData).then(res => {
        this.accountsList = res.data
      })
      this.accountsListA && this.accountsListA.forEach(item => {
        this.checkList.push(item.expressAccountId)
      })
    },
    // 全选
    handleAllSelect() {
      this.checkList = []
      this.accountsList.forEach(item => {
        this.checkList.push(item.expressAccountId)
      })
    },
    // 反选
    handleReverse() {
      const expressAccountId = this.accountsList.map(item => item.expressAccountId)
      const newList = this.checkList
      if (this.checkList.length <= 0) {
        this.checkList = expressAccountId
      } else {
        this.checkList = []
        expressAccountId.forEach(item => {
          if (!newList.includes(item)) {
            this.checkList.push(item)
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 380px;
  overflow-y: scroll;
  box-sizing: border-box;
  .checkbox {
    width: 100%;
    overflow: hidden;
  }
  .card {
    position: relative;
    width: 340px;
    height: 120px;
    background: #F7F7F7;
    border-radius: 4px;
    padding: 10px 0 0 15px;
    box-sizing: border-box;
    .card-img {
      width: 80px;
      height: 40px;
      border-radius: 4px;
      overflow: hidden;
    }
    .card-info1,
    .card-info2 {
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 19px;
      margin-top: 10px;
    }
    .info {
      height: 19px;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 19px;
    }
    .el-checkbox {
      position: absolute;
      top: 10px;
      right: 15px;
      /deep/ .el-checkbox__label {
        display: none;
      }
    }
    & + .card {
      margin-top: 10px;
    }
  }
  &::-webkit-scrollbar {
    width: 20px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
    border: 6px solid transparent;
    border-radius: 50px;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
/deep/.el-dialog__body {
  padding-right: 0;
}

.check-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 10px;
  .btn {
    width: 50px;
    height: 30px;
    background: #F7F7F7;
    color: #2395F3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 0;
    cursor: pointer;
    &:first-child {
      margin-right: 10px;
    }
  }
}
</style>
