var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier", class: [_vm.showFeeTabel ? "" : "pad"] },
    [
      !_vm.showFeeTabel
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "opt" }, [
                _vm._m(0),
                _c("div", { staticClass: "typetab" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permissson",
                          rawName: "v-permissson",
                          value: "PS",
                          expression: "'PS'",
                        },
                      ],
                      class: ["typetab-item", _vm.showTypeTab ? "" : "active"],
                      on: {
                        click: function ($event) {
                          return _vm.tabChange(0)
                        },
                      },
                    },
                    [_vm._v(" 派送 ")]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "permissson",
                          rawName: "v-permissson",
                          value: "GS",
                          expression: "'GS'",
                        },
                      ],
                      class: ["typetab-item", _vm.showTypeTab ? "active" : ""],
                      on: {
                        click: function ($event) {
                          return _vm.tabChange(1)
                        },
                      },
                    },
                    [_vm._v(" 关税 ")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "data-contanier" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTypeTab === 0,
                        expression: "showTypeTab === 0",
                      },
                    ],
                    staticClass: "type",
                  },
                  [
                    _c("TypeTab", {
                      attrs: { typeItems: _vm.typeItems },
                      on: { handleClick: _vm.handleClickTap },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "main",
                    style: {
                      marginLeft: _vm.showTypeTab === 0 ? "35px" : "0",
                      marginTop: _vm.showTypeTab === 1 ? "60px" : "30px",
                      width: _vm.showTypeTab ? "100%" : "calc(100% - 170px)",
                    },
                  },
                  [
                    _vm.showTypeTab === 0
                      ? [
                          _c(
                            "el-form",
                            {
                              ref: "ruleForm1",
                              attrs: {
                                model: _vm.ruleForm1,
                                rules: _vm.rules1,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        label: "始发地",
                                        prop: "initial",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "field" }, [
                                        _c(
                                          "div",
                                          { staticClass: "input" },
                                          [
                                            _c("el-cascader", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                options: _vm.initialData,
                                                placeholder: "请选择始发地",
                                              },
                                              on: {
                                                change: _vm.handleChangeInit,
                                              },
                                              model: {
                                                value: _vm.ruleForm1.initial,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm1,
                                                    "initial",
                                                    $$v
                                                  )
                                                },
                                                expression: "ruleForm1.initial",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm.showAddRess === 1
                                    ? [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                            },
                                            attrs: {
                                              label: "目的地",
                                              prop: "purpose",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "field" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "input" },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "请选择目的地",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm1
                                                              .purpose,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm1,
                                                              "purpose",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm1.purpose",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.purposeData,
                                                        function (item) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: item.value,
                                                              attrs: {
                                                                label:
                                                                  item.code +
                                                                  "-" +
                                                                  item.label,
                                                                value:
                                                                  item.code,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-right": "20px" },
                                      attrs: {
                                        label: "收件邮编",
                                        prop: "postalCode",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "field" }, [
                                        _c(
                                          "div",
                                          { staticClass: "input" },
                                          [
                                            _c("el-autocomplete", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "fetch-suggestions":
                                                  _vm.querySearchAsync,
                                                placeholder: "请输入收件邮编",
                                              },
                                              on: {
                                                select:
                                                  _vm.handleSelectPostCode,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ item }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.postalCode
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "addr",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.cityName +
                                                                  "," +
                                                                  item.provinceCode +
                                                                  "," +
                                                                  item.postalCode
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3487998681
                                              ),
                                              model: {
                                                value: _vm.ruleForm1.postalCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm1,
                                                    "postalCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm1.postalCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "私人地址",
                                        prop: "residential",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "field" }, [
                                        _c(
                                          "div",
                                          { staticClass: "switch" },
                                          [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-text": _vm.ruleForm1
                                                  .residential
                                                  ? "开启"
                                                  : "关闭",
                                                "inactive-color": "#dcdfe6",
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm1.residential,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm1,
                                                    "residential",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm1.residential",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("el-form-item", [
                                    _c("div", { staticClass: "field" }, [
                                      _vm.accountsData.quotesType === 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "btn",
                                              on: {
                                                click: function ($event) {
                                                  _vm.drawer = true
                                                },
                                              },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/images/order/rmb.png"),
                                                },
                                              }),
                                              _vm._v(" 卡派询价 "),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                              _c("div", { staticClass: "title" }, [
                                _c("span", [_vm._v("*")]),
                                _vm._v(" 选择账号"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "row",
                                  staticStyle: { "margin-top": "5px" },
                                },
                                [
                                  _vm._l(
                                    _vm.accountsList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.expressAccountId,
                                          staticClass: "account",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "account-img" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require(`@/assets/images/order/${item.expressSupplier.toLowerCase()}.png`),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "account-info1" },
                                            [
                                              _vm._v(" 账       号："),
                                              _c(
                                                "span",
                                                { staticClass: "info" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.accountNumber)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "account-info2" },
                                            [
                                              _vm._v(" 服务类型："),
                                              _c(
                                                "span",
                                                { staticClass: "info" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.expressService)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-remove-outline",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDeleteAcc(
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "addaccount",
                                      on: { click: _vm.handleAddAccount },
                                    },
                                    [
                                      _c("div", { staticClass: "addtext" }, [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/order/add.png"),
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" 选择账号 "),
                                      ]),
                                    ]
                                  ),
                                ],
                                2
                              ),
                              _vm.showAddRess === 0 &&
                              _vm.accountsList.length > 0
                                ? [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v("地址"),
                                    ]),
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        { staticClass: "address" },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "650px" },
                                            attrs: {
                                              placeholder: "请输入地址",
                                            },
                                            model: {
                                              value: _vm.addressLine,
                                              callback: function ($$v) {
                                                _vm.addressLine = $$v
                                              },
                                              expression: "addressLine",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                : _vm._e(),
                              _c("div", { staticClass: "title field" }, [
                                _c("span", { staticClass: "require" }, [
                                  _vm._v("* "),
                                ]),
                                _vm._v("包裹信息 "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "package" },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      ref: "ruleForm2",
                                      staticClass: "row",
                                      attrs: {
                                        model: _vm.ruleForm2,
                                        rules: _vm.rules2,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "件数",
                                            prop: "packageNumber",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input2" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请输入件数",
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm2
                                                          .packageNumber,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm2,
                                                          "packageNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm2.packageNumber",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "单件实重",
                                            prop: "weight",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input3" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入单件实重",
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm2.weight,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm2,
                                                          "weight",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm2.weight",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "长",
                                            prop: "length",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input2" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请输入长度",
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm2.length,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm2,
                                                          "length",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm2.length",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "宽", prop: "width" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input2" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请输入宽度",
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm2.width,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm2,
                                                          "width",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm2.width",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "高",
                                            prop: "height",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input2" },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "请输入高度",
                                                      oninput:
                                                        "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm2.height,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm2,
                                                          "height",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm2.height",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "单位",
                                            prop: "unitType",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "input2" },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择单位",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm2
                                                            .unitType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm2,
                                                            "unitType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm2.unitType",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "LB/IN",
                                                          value: "0",
                                                        },
                                                      }),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "KG/CM",
                                                          value: "1",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("el-form-item", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "field",
                                            staticStyle: {
                                              "margin-right": "10px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleAddPack(
                                                      "ruleForm2"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("添加")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("el-form-item", [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: { click: _vm.resetTabel },
                                              },
                                              [_vm._v("重置")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "packageList" }, [
                                _c("ul", [
                                  _c("li", { staticClass: "header" }, [
                                    _c("div", { staticClass: "data" }, [
                                      _c("div", [_vm._v("包裹号")]),
                                      _c("div", [_vm._v("实重")]),
                                      _c("div", [_vm._v("长")]),
                                      _c("div", [_vm._v("宽")]),
                                      _c("div", [_vm._v("高")]),
                                      _c("div", [_vm._v("单位")]),
                                      _c("div", [_vm._v("操作")]),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "packageList-content" },
                                    _vm._l(
                                      _vm.packageList,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          { key: item.packageNumber },
                                          [
                                            _c("div", { staticClass: "data" }, [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.packageNumber)
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.realWeight.weight)
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.dimension.length)
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.dimension.width)
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.dimension.height)
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.dimension
                                                        .unitType === 0
                                                        ? "LB/IN"
                                                        : "KG/CM"
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "delete",
                                                  style: {
                                                    width:
                                                      _vm.packageList.length > 3
                                                        ? "calc(16.66% - 12px)"
                                                        : "16.66%",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    attrs: { title: "删除" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDeleteBack(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                                _c("div", { staticClass: "sum" }, [
                                  _c("span", [
                                    _vm._v("总实重："),
                                    _c("span", { staticClass: "sum-no" }, [
                                      _vm._v(_vm._s(_vm.total) + "KG"),
                                    ]),
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "40px" } },
                                    [
                                      _vm._v("总件数："),
                                      _c("span", { staticClass: "sum-no" }, [
                                        _vm._v(
                                          _vm._s(_vm.packageList.length) + "件"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "fee" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "feeBtn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleFeeCompute(
                                            "ruleForm1"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("费用估算")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                    _vm.showTypeTab === 1 ? [_c("tariff-fee")] : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("select-account-dialog", {
                attrs: {
                  accountsListA: _vm.accountsList,
                  accountsData: _vm.accountsData,
                  dialogFormVisible: _vm.dialogFormVisible,
                },
                on: { ok: _vm.ok, cancel: _vm.cancel },
              }),
            ],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "tabel-data" },
              [
                _c("div", { staticClass: "back-fee" }, [
                  _c(
                    "div",
                    { staticClass: "back", on: { click: _vm.handleBackFee } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/order/back.png"),
                          alt: "",
                        },
                      }),
                      _vm._v("返回估算 "),
                    ]
                  ),
                  _vm._m(1),
                ]),
                _vm.height !== 0
                  ? _c("BaseTable", {
                      attrs: {
                        height: _vm.height,
                        isPager: false,
                        tableData: _vm.tableData,
                        loading: _vm.loading,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "errorMessage",
                            fn: function (row) {
                              return [
                                row.tIndex === 0 && !row.scope.errorMessage
                                  ? _c(
                                      "div",
                                      { staticClass: "optimal flex_c_c" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/order/praise.png"),
                                          },
                                        }),
                                        _vm._v("价格最优 "),
                                      ]
                                    )
                                  : _vm._e(),
                                row.scope.errorMessage
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          tabindex: 2000,
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content":
                                                    "space-between",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      width: "150px",
                                                      "word-wrap": "break-word",
                                                      "word-break": "break-all",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.scope.errorMessage
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "15px",
                                                      "margin-left": "15px",
                                                      width: "20px",
                                                      height: "20px",
                                                      cursor: "pointer",
                                                    },
                                                    attrs: { title: "复制" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleTipCopy(
                                                          row.scope.errorMessage
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: require("@/assets/images/order/copy.png"),
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass: "el-icon-warning red",
                                        }),
                                      ]
                                    )
                                  : _c("i", {
                                      staticClass: "el-icon-success green",
                                    }),
                              ]
                            },
                          },
                          {
                            key: "express",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(row.scope.express) +
                                    " " +
                                    _vm._s(row.scope.service) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "address",
                            fn: function (row) {
                              return _vm._l(
                                row.scope.address,
                                function (item, $index) {
                                  return _c(
                                    "p",
                                    { key: $index, staticClass: "item-detail" },
                                    [
                                      _c("label", [_vm._v(_vm._s(item.label))]),
                                      _c("span", [_vm._v(_vm._s(item.value))]),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                          {
                            key: "surcharge",
                            fn: function (row) {
                              return _vm._l(
                                row.scope.surcharge,
                                function (item, $index) {
                                  return _c(
                                    "p",
                                    { key: $index, staticClass: "item-detail" },
                                    [
                                      _c("label", [_vm._v(_vm._s(item.label))]),
                                      _c("span", [_vm._v(_vm._s(item.value))]),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                          {
                            key: "surchargeRMB",
                            fn: function (row) {
                              return _vm._l(
                                row.scope.surchargeRMB,
                                function (item, $index) {
                                  return _c(
                                    "p",
                                    { key: $index, staticClass: "item-detail" },
                                    [
                                      _c("label", [_vm._v(_vm._s(item.label))]),
                                      _c("span", [_vm._v(_vm._s(item.value))]),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                          {
                            key: "weight",
                            fn: function (row) {
                              return _vm._l(
                                row.scope.weight,
                                function (item, $index) {
                                  return _c(
                                    "p",
                                    { key: $index, staticClass: "item-detail" },
                                    [
                                      _c("label", [_vm._v(_vm._s(item.label))]),
                                      _c("span", [_vm._v(_vm._s(item.value))]),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                          {
                            key: "rate",
                            fn: function (row) {
                              return _vm._l(
                                row.scope.rate,
                                function (item, $index) {
                                  return _c(
                                    "p",
                                    { key: $index, staticClass: "item-detail" },
                                    [
                                      _c("label", [_vm._v(_vm._s(item.label))]),
                                      _c("span", [_vm._v(_vm._s(item.value))]),
                                    ]
                                  )
                                }
                              )
                            },
                          },
                          {
                            key: "costAmount",
                            fn: function (row) {
                              return [
                                _vm._v(
                                  " " + _vm._s(row.scope.costAmount) + " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "opt",
                            fn: function (row) {
                              return [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "left",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "ul",
                                      _vm._l(
                                        row.scope.surchargeItems,
                                        function (a, i) {
                                          return _c(
                                            "li",
                                            {
                                              key: i,
                                              staticClass: "detail-item",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "16px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(a.label) + "：")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "detail-money",
                                                  staticStyle: {
                                                    "margin-right": "16px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(a.value) +
                                                      " " +
                                                      _vm._s(a.currency) +
                                                      " RMB"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "tips",
                                        staticStyle: { color: "#2395f3" },
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [_vm._v(" 费用明细 ")]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        148224385
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
      _c("CarEnquiryDrawer", {
        attrs: { drawer: _vm.drawer },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "opt-title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/order/feeTrial-img.png"),
          alt: "",
        },
      }),
      _vm._v(" 费用试算 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "current" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
      _vm._v("估算结果"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }